import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SelectField, MultiSelectField, BooleanField, EventTimeField, InputField } from '../../FormFields';

const eventType = [ {
    label: 'COVID-19 vaccination only',
    value: 'COVID-19 vaccination only'
},{
    label: 'Routine childhood vaccinations only',
    value: 'Routine childhood vaccinations only'
},{
    label: 'COVID-19 and flu vaccinations only',
    value: 'COVID-19 and flu vaccinations only'
},{
    label: 'Both COVID-19 and routine childhood vaccinations',
    value: 'Both COVID-19 and routine childhood vaccinations'
},{
    label: 'COVID-19, flu, and routine childhood vaccinations',
    value: 'COVID-19, flu, and routine childhood vaccinations'    
},{
    label: 'Educational event (no vaccines to be administered)',
    value: 'Educational event'
} ];

const vacPreference = [ {
    label: 'Pfizer',
    value: 'Pfizer'
},{  
    label: 'Moderna',
    value: 'Moderna'
},{  
    label: 'Novavax',
    value: 'Novavax'
},{  
    label: 'No preference/Not applicable',
    value: 'No preference/Not applicable'
} ];

const demographics = [ {
    label: '11 years and younger',
    value: '11 years and younger',
    disabled: false
},{  
    label: '12-17 years and under',
    value: '12-17 years and under',
    disabled: false
},{   
    label: '18 – 39 years',
    value: '18 – 39 years',
    disabled: false
},{  
    label: '40-64 years',
    value: '40-64 years',
    disabled: false
},{     
    label: '65 and over',
    value: '65 and over',
    disabled: false
} ];

const HealthInsurance = [ {
    label: 'All are insured',
    value: 'All are insured'
},{  
    label: 'Most are insured',
    value: 'Most are insured'
},{      
    label: 'Most are uninsured',
    value: 'Most are uninsured'
},{
    label: 'All are uninsured',
    value: 'All are uninsured'
},{
    label: 'Unknown',
    value: 'Unknown'
} ];

export default function EventInformation(props) {
    const {
        formField: {
            vaccination_event_type,
            request_time_of_event,
            part_of_larger_event,
            covid_vaccine_preference,
            target_demographics,
            insurance_status,
            vaccination_status_assessed,
            vaccination_wanted_indiv_num,
            edu_event_topics_to_cover
        },
        formProps
    } = props;

    const childhoodAges = [ '12-17 years and under','11 years and younger' ];
    const adultAges = [ '18 – 39 years','40-64 years','65 and over' ];

    const [ showVaccine, setShowVaccine ] = useState(false);
    useEffect((evt) => {
        // sets up logic to clear out fields if not ed or not vaccine event
        try {
            const currentVal = props.formProps.current;            
            const eventtypeval = currentVal.values.vaccination_event_type;

            if (eventtypeval != '') {
                if (eventtypeval != 'Educational event') {
                    currentVal.setFieldValue('edu_event_topics_to_cover', '');
                    demographics.map(item => {
                        if (eventtypeval == 'Routine childhood vaccinations only' && childhoodAges.indexOf(item.value) == -1) {
                            item.disabled = true;
                        } else {
                            item.disabled = false;
                        }
                    });
                    if (eventtypeval == 'Routine childhood vaccinations only') {
                        // if childhood switch and demographics contains adult value, clear it out
                        if (adultAges.some(age => currentVal.values.target_demographics.includes(age))) {
                            currentVal.setFieldValue('target_demographics', '');
                        }                    
                        currentVal.setFieldValue('covid_vaccine_preference', 'No preference/Not applicable');
                    } else {
                        currentVal.setFieldValue('covid_vaccine_preference', '');
                    }
                    setShowVaccine(true);
                } else {
                    currentVal.setFieldValue('covid_vaccine_preference', '');
                    currentVal.setFieldValue('target_demographics', '');
                    currentVal.setFieldValue('insurance_status', '');
                    setShowVaccine(false);
                }            
            } else {
                setShowVaccine(true);
            }
        }
        catch {
            setShowVaccine(false); 
        }
        // 
    }, [ props.formProps.current.values.vaccination_event_type ]);  

	return (
    <React.Fragment>
        <section className='flex flex-wrap w-full md:w-10/12 mx-auto p-2 bg-white bg-opacity-80 lg:bg-transparent' aria-labelledby='organization_contact'>
            <Typography variant='h6' gutterBottom>
                Event Information:
            </Typography>
            <Grid container spacing={ 3 } className="border-b pb-10 mb-10 border-gray-200">
                <Grid item xs={ 12 }>
                    <p className="italic">1.  What type of vaccination event are you requesting:</p>
                    <SelectField
                        name={ vaccination_event_type.name }
                        label={ vaccination_event_type.label }
                        data={ eventType }
                        fullWidth
                    />
                    <p className='font-bold text-red-900 text-sm mt-1' hidden={ props.formProps.current.values.vaccination_event_type != 'Both COVID-19 and routine childhood vaccinations' }> (Please note that not all providers offer both COVID and routine vaccinations; fewer providers will be eligible to accept a request for this type of event.)</p> 
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">2.  When would you like to hold your event?</p>
                    <p className="text-sm">Please note, requests should be submitted at least <b>one month</b> prior to the scheduled event</p>
                    <EventTimeField
                        name={ request_time_of_event.name }
                        label={ request_time_of_event.label }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">3.  Will the vaccination event be part of a larger event (e.g. community fair, concert, conference, etc.)</p>
                    <BooleanField className="w-full"
                        name={ part_of_larger_event.name }
                        label={ part_of_larger_event.label }
                    />
                </Grid>
            </Grid>
            <Grid container spacing={ 3 } className="border-b pb-10 mb-10 border-gray-200">
                <Grid item xs={ 12 }>
                    <p className='font-bold text-red-900' hidden={ showVaccine }>Skip to question 7 section since this is an educational event!</p>                    
                    <Typography variant='h6' className='text-lg' gutterBottom>
                        For Vaccine Events:
                    </Typography>
                    <p className="italic">4.  Do you have a COVID-19 vaccine preference for your event?</p>
                    <MultiSelectField
                        name={ covid_vaccine_preference.name }
                        label={ covid_vaccine_preference.label }
                        data={ vacPreference }
                        disabled={ !showVaccine }                      
                        fullWidth
                    />                    
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">5.  Which demographic(s) is your event primarily targeting for vaccination (select no more than 2)?</p>
                    <MultiSelectField
                        name={ target_demographics.name }
                        label={ target_demographics.label }
                        data={ demographics }
                        disabled={ !showVaccine }
                        limit='2'                        
                        fullWidth
                    />
                    <p className='font-bold text-red-900 text-sm mt-1' hidden={ props.formProps.current.values.vaccination_event_type != 'Routine childhood vaccinations only' && props.formProps.current.values.covid_vaccine_preference == 'No preference/Not applicable' }> (Some options might be disabled based on choices to previous questions)</p> 
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">6.  For providers that will need to submit for reimbursement, what is the health insurance status of the population to be vaccinated?</p>
                    <SelectField
                        name={ insurance_status.name }
                        label={ insurance_status.label }
                        data={ HealthInsurance }
                        disabled={ !showVaccine }                        
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 12 }>
                    <Typography variant='h6' className='text-lg' gutterBottom>
                        For All Events:
                    </Typography>
                    <p>Providing an estimate of the number of individuals that you anticipate at your event will help match you with the most appropriate provider. If you have already assessed your target population’s vaccination status, please enter below the approximate number of individuals that you expect to be vaccinated at the event. If you do not know the vaccination status of your target population, please provide the approximate size of the population you will be targeting for this event.</p>
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">7.  How many individuals do you anticipate at your event?</p>
                    <div className="flex flex-col md:flex-row m-4 justify-between">
                        <p className="my-auto">Have you assessed your target population's vaccination status?</p>
                        <BooleanField className="w-36 mx-auto md:m-0"
                            name={ vaccination_status_assessed.name }
                            label={ vaccination_status_assessed.label }
                        />
                    </div>
                    <div className="flex flex-col sm:flex-row m-4 justify-between">
                        <p className="my-auto">How many people do you anticipate at your event?</p>
                        <InputField type='number' className="w-36 mx-auto md:m-0" name={ vaccination_wanted_indiv_num.name } label={ vaccination_wanted_indiv_num.label } fullWidth />
                    </div>
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">8.  For educational events, what topic(s) would you like the provider to cover: </p>
                    <p className='font-bold text-red-900' hidden={ !showVaccine }>Skip if not an educational event!</p>
                    <InputField 
                        name={ edu_event_topics_to_cover.name } 
                        label={ edu_event_topics_to_cover.label }
                        disabled={ showVaccine }
                        multiline
                        rows="3"
                        fullWidth />
                </Grid>
            </Grid>            
        </section>
    </React.Fragment>
	);
};
