import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormControl,
  FormHelperText,
  TextField,
  Radio, 
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';

function EventPlaceField(props) {
  const { label, ...rest } = props;
  const [ field, meta, helper ] = useField(props);
  const { value: selectedVal } = field;
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const [ selectedType, setSelectedType ] = useState(null); 
  const [ place, setPlace ] = useState('');  

  useEffect(() => {
    if (selectedVal != null) {    
      // if value exists - repopulate component
      if (selectedVal.indexOf('At the following address') > -1) {
        setSelectedType('at location');
        setPlace(selectedVal.replace('At the following address: ', ''));
      } else {
        setSelectedType(selectedVal);
      }
    }
  }, [ ]);

  useEffect(() => {
    if (selectedType != 'at location') {
      setValue(selectedType);
    } else {
      if (place != ''){
        setValue('At the following address: ' + place);
      } else {
        setValue(null);
      }
    } 
  }, [ selectedType ]);

  useEffect(() => {  
    if (place != '') {
      setValue('At the following address: ' + place);
    }
  }, [ place ]);  

  function _onChange(e) {
    setSelectedType(e.target.value);
  }

  function _handleAddressChange(e) {
    if (e.target.value != '') {
      setValue('At the following address: ' + e.target.value);
    } else {
      setValue(null);
    }
  }

  function _renderHelperText() {

    if (isError) {
      return <FormHelperText>{ error }</FormHelperText>;
    }
  }

  function _helperDom() {
    switch(selectedType) {
      case 'at location':
        return (
            <TextField
              label='Location Address'
              type="text"
              defaultValue={ place }
              onChange={ _handleAddressChange }
              fullWidth
              shrink="true" />
        );
    }
  }

  return (
      <FormControl { ...rest } error={ isError }>
          <RadioGroup
            defaultValue=""
            name="radio-buttons-group"
            onChange={ _onChange }
            value={ selectedType }
          >
              <FormControlLabel value="at location" control={ <Radio /> } label={ <div>At the following address - include below:</div> } />
              <FormControlLabel value="At the provider’s location" control={ <Radio /> } label={ <div>At the provider’s location</div> }/>
              <FormControlLabel value="Virtually (for educational events only)" control={ <Radio /> } label={ <div>Virtually (for educational events only)</div> }/>
              <FormControlLabel value="Unsure" control={ <Radio /> } label={ <div>Unsure</div> }/>              
          </RadioGroup>
          {  _helperDom() }
          { _renderHelperText() }
      </FormControl>
  );
}

export default EventPlaceField;
