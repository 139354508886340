import React, { useState, useRef } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { theme, useStyle } from './styles';
import useStyles from './styles';
import { createMuiTheme, ThemeProvider  } from '@material-ui/core/styles';

// form pieces
import validationSchema from './FormModel/validationSchema';
import requestFormModel from './FormModel/requestFormModel';
import formInitialValues from './FormModel/formInitialValues';
import ContactInformation from './Forms/ContactInformation';
import OrgInformation from './Forms/OrgInformation';
import EventInformation from './Forms/EventInformation';
import SpaceInformation from './Forms/SpaceInformation';
import HRInformation from './Forms/HRInformation';

// form created following process here:
// https://medium.com/@nphivu414/build-a-multi-step-form-with-react-hooks-formik-yup-and-materialui-fa4f73545598

const steps = [ 'Organization', 'Contact', 'Event Info', 'Physical Space', 'Human Resources' ];
const { formId, formField } = requestFormModel;
const emailVaccine = process.env.REACT_APP_VACCINE_EMAIL;

export const ReqForm = props => {
    const classes = useStyles();
    const [ activeStep, setActiveStep ] = useState(0);
    const currentValidationSchema = validationSchema[ activeStep ];
    const isLastStep = activeStep === steps.length - 1;
    const [ formSubmitted, setFormSubmitted ] = useState(false);
    const [ formError, setFormError ] = useState(false);
    const [ isActive, setIsActive ] = useState(false);
    const formRef = useRef();

    function _renderStepContent(step) {
        switch (step) {
        case 0:
            return <OrgInformation formField={ formField } />;
        case 1:
            return <ContactInformation formField={ formField } />;
        case 2:
            return <EventInformation formField={ formField } formProps={ formRef } />;
        case 3:
            return <SpaceInformation formField={ formField } formProps={ formRef } />;
        case 4:
            return <HRInformation formField={ formField } />;
        default:
            return <div>Not Found</div>;
        }
    }

    const handleShow = ()=>{
        setIsActive(true);
    }  

    async function _submitForm(values, actions) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        };
        const { REACT_APP_REQUEST_API_URL } = process.env;
        const apiBaseUrl = REACT_APP_REQUEST_API_URL;
        fetch(apiBaseUrl, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log('response: ', data);
                if (!data.errors){
                    setFormError(false);
                    setFormSubmitted(true);
                    actions.setSubmitting(false);
                    setActiveStep(activeStep + 1);                    
                } else {
                    // catches errors - not success false 
                    // success false is mail not sent usually
                    setFormError(true);
                    setFormSubmitted(true);                    
                    actions.setSubmitting(false);
                    setActiveStep(activeStep + 1);                    
                }
            })
            .catch(error => {
                console.log(error);
                setFormError(true);
                setFormSubmitted(true);              
                actions.setSubmitting(false);
                setActiveStep(activeStep + 1);
        });
    }

    function _handleSubmit(values, actions) {
        if (isLastStep) {
        _submitForm(values, actions);
        } else {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    return (
        <ThemeProvider theme={ theme }>
            <div className="container">
                { activeStep === steps.length ? (
                    <div>
                        {
                            (!formError) ? (
                                <div className="flex flex-wrap w-full md:w-1/2 mx-auto p-2 text-center">
                                    <p className="w-full">Thank you for submitting your request for vaccination.</p>
                                    <p className="w-full">Look out for follow up emails with more information on next steps and follow ups.</p>
                                </div>
                            ) : (
                                <div>
                                    <div className="flex flex-wrap w-full md:w-1/2 mx-auto p-2 text-center">
                                        <p className="w-full">Failed to submit!</p>
                                        <p className="w-full">Please contact an administrator.</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                ) : (
                    <React.Fragment>
                        { !formSubmitted && (
                            <React.Fragment>
                                <Stepper activeStep={ activeStep } className={ classes.stepper } alternativeLabel>
                                    {steps.map(label => (
                                        <Step key={ label }>
                                            <StepLabel><span className="hidden md:block">{ label }</span></StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <Formik
                                    innerRef={ formRef }
                                    initialValues={ formInitialValues }
                                    validationSchema={ currentValidationSchema }
                                    onSubmit={ _handleSubmit }
                                >
                                    { ({ isSubmitting }) => (
                                        <Form id={ formId }>
                                            { _renderStepContent(activeStep) }
                                            <div className={ classes.buttons }>
                                                { activeStep !== 0 && (
                                                    <Button onClick={ _handleBack } className={ classes.button }>
                                                        Back
                                                    </Button>
                                                ) }
                                                <div className={ classes.wrapper }>
                                                    <Button
                                                        disabled={ isSubmitting }
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className={ classes.button }
                                                    >
                                                        { isLastStep ? 'Submit request' : 'Next' }
                                                    </Button>
                                                    { isSubmitting && (
                                                        <CircularProgress
                                                            size={ 24 }
                                                            className={ classes.buttonProgress }
                                                        />
                                                    ) }
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        </ThemeProvider>
  );
}
