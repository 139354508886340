import React from 'react';

function Footer() {
    return(
        <footer className="">
            <div className="lg:container mx-auto p-2 lg:flex flex-row justify-between">
                <div className="w-full lg:w-1/2">
                    <img className="h-16 mx-auto" src={ process.env.PUBLIC_URL + '/assets/Corona_Logo.png' }
                        alt='Corona Logo'/>
                </div>
                <div className="w-full lg:w-1/2 my-auto">
                    <img className="h-10 mx-auto" src={ process.env.PUBLIC_URL + '/assets/DCHealth.png' }
                        alt='DC HealthLogo'/>
                </div>
            </div>
        </footer>
    );  
}

export default Footer;