import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

export default function PhoneInput(props) {
  const { errorText, ...rest } = props;
  const [ field, meta, helper ] = useField(props);
  const [ touched, error ] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return error;
    }
  }

  return (
      <InputMask
          mask="(999) 999-9999"
          { ...rest }
          { ...field }
          >
          {(inputProps) => <TextField
            { ...inputProps }
            type="text"
            error={ isError }
            helperText={ _renderHelperText() }
            />}
      </InputMask>
  );
}