import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  ListItemText,
  Box,
  Chip
} from '@material-ui/core';

function MultiSelectField(props) {
  const { label, data, limit, ...rest } = props;
  const [ field, meta, helper ] = useField(props);
  const { setValue } = helper;
  const { value: selectedVal } = field;
  const [ touched, error ] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const [ choiceValues, setChoiceValues ] = useState([]);

  useEffect(() => {
    if (selectedVal) {
      setChoiceValues(selectedVal.split(';'));
    } else {
      setChoiceValues([]);
    }
  }, [ selectedVal ]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log(typeof value);

    // if limit is set, restrict to that limit and alert user
    if (limit && parseInt(limit)) {
      if (value.length > parseInt(limit)) {
        alert('Limit of ' + limit + ' choices');
        return;
      }
    }
    setChoiceValues(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(';') : value,
    );
    setValue(value.join(';'));
  };

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{ error }</FormHelperText>;
    }
  }

  return (
      <FormControl { ...rest } error={ isError }>
          {/* <InputLabel shrink>{ label }</InputLabel> */}
          <Select 
              { ...field } 
              value={ choiceValues } 
              multiple={ true } 
              onChange={ handleChange } 
              renderValue={ (selected) => (
                  <Box className='flex flex-wrap gap-2'>
                      { selected.map((value) => (
                          <Chip key={ value } label={ value } />
                      )) }
                  </Box>
              ) }>
              { data.map((item, index) => (
                  <MenuItem 
                      key={ index }
                      value={ item.value }
                      disabled={ item.disabled || false }>
                      <Checkbox checked={ choiceValues.indexOf(item.value) > -1 } />
                      <ListItemText primary={ item.label } />
                  </MenuItem>
              ))}
          </Select>
          { _renderHelperText() }
      </FormControl>
  );
}

MultiSelectField.defaultProps = {
  data: []
};

MultiSelectField.propTypes = {
  data: PropTypes.array.isRequired
};

export default MultiSelectField;