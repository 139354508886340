import React from 'react';
import { Link } from "react-router-dom";
import {
    Button
  } from '@material-ui/core';
import useStyles from '../components/RequestForm/styles';
import theme from '../components/RequestForm/styles';
import { ThemeProvider  } from '@material-ui/core/styles';

const Home = props => {
    const emailVaccine = process.env.REACT_APP_VACCINE_EMAIL;
    const classes = useStyles();

    return (
        <ThemeProvider theme={ theme }>

        <div className="lg:w-1/2 container mx-auto p-2 m-4">
            <div className="p-3 text-gray-700 body-font">
                <h1 className="text-2xl text-center">Welcome to the Vaccine Exchange!</h1>
                <div>
                    <p className='border-solid border-2 my-2 border-black px-2 py-2'>
                        <strong>Fall 2023 Update</strong> - DC Health is partnering with health care providers to provide immunization clinics at schools. 
                        For any schools interested in requesting a vaccine clinic, please reference our &nbsp;
                        <a className="cursor-pointer underline text-secondary-dark hover:text-secondary" target="_blank" href="https://dcgov.app.box.com/v/exchange-calendar-2023">2023 Calendar</a> for more information on dates/availability. 
                    </p>
                    <p className="text-left">
                        This form is your entry point for requesting a vaccination event in partnership with qualified vaccine providers.
                        <br/><br/>
                        Please note:
                        <br/><br/>
                    </p>
                    <ol className="list-decimal mx-12">
                        <li> DC Health is not able to guarantee your event request will be accepted. We do our best to facilitate connections, however provider availability is limited.</li>
                        <br/>
                        <li> Event requests should be submitted at least <b>one month in advance</b> to allow providers enough time to review your request, contact you, and plan for a successful clinic. </li>
                        <br/>
                    </ol>
                    <p>    
                        To submit a request to the Vaccine Exchange, please complete all the form fields below. Once your request has been submitted, if it is later accepted by a provider, you will receive a confirmation email with further details, and the provider will coordinate with you directly. 
                        <br/>
                        <br/>
                        If you have any questions regarding your request, please email:&nbsp;
                        <a className="cursor-pointer underline text-secondary-dark hover:text-secondary" href={ 'mailto:' + emailVaccine }>{ emailVaccine }</a>.
                    </p>
                    <br/>
                    <p className="text-left italic text-sm">
                        Disclaimer: This service is offered by DC Health and DC Government as a free tool to allow organizations to
                        submit information such that qualified providers may review and connect with said organizations.
                        DC Health cannot guarantee connections being made or provider schedules.
                    </p>
                </div>
                <div className='text-center mt-10'>
                    <Link  className="px-6 py-3 text-blue-100 no-underline bg-green-800 rounded hover:bg-green-900 hover:underline hover:text-blue-200" to={"/form"}>NEXT</Link >
                </div>

            </div>
        </div>
        </ThemeProvider>
    );
}

export default Home;
