import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, BooleanField, SelectField, MultiSelectField, EventPlaceField } from '../../FormFields';

const indoorOutdoors = [
    {
        label: 'Completely indoors',
        value: 'Completely indoors'
    },{
        label: 'Completely outdoors',
        value: 'Completely outdoors'
    },{
        label: 'Using a combination of indoor/outdoor space',
        value: 'Using a combination of indoor/outdoor space'
    },{
        label: 'Unsure; we would like to discuss options',
        value: 'Unsure; we would like to discuss options'
    }
];

const ammenities = [
    {
        label: 'Secure WiFi in all event areas',
        value: 'Secure WiFi in all event areas'
    },{
        label: 'Tables (e.g. check-in, administration, check-out)',
        value: 'Tables (e.g. check-in, administration, check-out)'
    },{
        label: 'Chairs (e.g. waiting, administration, observation)',
        value: 'Chairs (e.g. waiting, administration, observation)'
    },{
        label: 'Access to a power source in all event areas',
        value: 'Access to a power source in all event areas'
    },{
        label: 'Audio-visual equipment (including meeting software for virtual educational events)',
        value: 'Audio-visual equipment (including meeting software for virtual educational events)'
    },{
        label: 'Hand and surface sanitizers',
        value: 'Hand and surface sanitizers'
    },{
        label: 'Dedicated parking for vaccine provider staff',
        value: 'Dedicated parking for vaccine provider staff'
    }
];

export default function SpaceInformation(props) {
    const {
        formField: {
            event_location,
            hosting_plan_indoor_outdoor,
            facility_capacity_bad_weather,
            on_site_amenities,
            language_needs
        }
      } = props;

      const [ showSpace, setShowSpace ] = useState(false);
      useEffect(() => {
          try {
              const currentVal = props.formProps.current;
              if (currentVal.values.event_location.indexOf('At the following address') > -1) {
                  setShowSpace(true);
              } else {
                // reset the 4 fields if remove at following address
                if (currentVal.values.hosting_plan_indoor_outdoor != '') {
                    currentVal.setFieldValue('hosting_plan_indoor_outdoor', '');
                }
                if (currentVal.values.facility_capacity_bad_weather != null) {
                    currentVal.setFieldValue('facility_capacity_bad_weather', null);
                }
                if (currentVal.values.on_site_amenities != '') {
                    currentVal.setFieldValue('on_site_amenities', '');
                }
                if (currentVal.values.language_needs != '') {
                    currentVal.setFieldValue('language_needs', '');
                }
                setShowSpace(false);
              }
          }
          catch {
            setShowSpace(false);
          }
      }, [ props ]);

	return (
    <React.Fragment>
        <section className='flex flex-wrap w-full md:w-10/12 mx-auto p-2 bg-white bg-opacity-80 lg:bg-transparent' aria-labelledby='organization_contact'>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 12 }>
                    <Typography variant="h6" gutterBottom>
                        Physical Space:
                    </Typography>
                    <p>Vaccine clinic operations generally require use of three distinct spaces for the following functions: check-in, vaccine administration, and post-vaccination observation. All spaces must allow for social distancing to be maintained. If you are requesting a provider for an educational event, please indicate whether the event will be virtual or the in-person location.</p>
                </Grid>                
                <Grid item xs={ 12 }>
                    <p className="italic">9.  Where would you like to have the event take place?</p>                    
                    <EventPlaceField
                        name={ event_location.name }
                        label={ event_location.label }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <p className='font-bold text-red-900' hidden={ showSpace }>Skip these questions since not hosting provider!</p>
                    <Typography variant='h6' className='text-lg' gutterBottom>
                        For events hosted at a specified address:
                    </Typography>   
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">10.	Do you plan to host the vaccine event...?</p>
                    <SelectField
                        name={ hosting_plan_indoor_outdoor.name }
                        label={ hosting_plan_indoor_outdoor.label }
                        data={ indoorOutdoors }
                        disabled={ !showSpace }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">11.	In the event of inclement weather, does your facility have capacity to host all vaccine operations indoors?</p>
                    <BooleanField className="w-full"
                        name={ facility_capacity_bad_weather.name }
                        label={ facility_capacity_bad_weather.label }
                        isDisabled={ !showSpace }                        
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">12.	If needed, which of the following on-site amenities are you be able to supply for the provider:</p>
                    <MultiSelectField
                        name={ on_site_amenities.name }
                        label={ on_site_amenities.label }
                        data={ ammenities }
                        disabled={ !showSpace }                        
                        fullWidth
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">13.  If the population has specific language access needs or needs other special accommodation, please describe:</p>
                    <InputField 
                        name={ language_needs.name }
                        label={ language_needs.label }
                        fullWidth 
                        disabled={ !showSpace }
                        />
                </Grid>                
            </Grid>
        </section>
    </React.Fragment>
	);
};