import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Header extends Component {
    render() {
        const { altText, image } = this.props;
        return (
            <header className="bg-primary-light shadow-lg">
                <div className="lg:container mx-auto p-2">
                    <img className="w-32 my-2" src={ process.env.PUBLIC_URL + `/assets/${ image }` } alt={ altText }/>
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    image: PropTypes.string,
    altText: PropTypes.string
}

export default Header;