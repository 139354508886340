import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Radio, 
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

function EventTimeField(props) {
  const { label, ...rest } = props;
  const [ field, meta, helper ] = useField(props);
  const { value: selectedVal } = field;
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;

  const minDate = new Date();
  const [ selectedType, setSelectedType ] = useState(null); 
  const [ months, setMonths ] = useState('');  
  const [ date, setDate ] = useState(null);
  const monthList = [ 1, 2, 3, 4, 5, 6 ];

  useEffect(() => {
    if (selectedVal != null) {
      // if value exists - repopulate component
      if (selectedVal.indexOf('Within') > -1) {
        setSelectedType('within');
        const monthVal = selectedVal.replace('Within ', '').replace(' months', '');
        setMonths(monthVal);
      } else if (selectedVal.indexOf('On date') > -1) {
        setSelectedType('on date');
        const dateVal = selectedVal.replace('On date: ', '');
        setDate(new Date(dateVal));
      } else if (selectedVal.indexOf('As soon') > -1) {
        setSelectedType('asap');
      }
    }
  }, [ ]);

  useEffect(() => {
    if (selectedType == 'asap') {
      setValue('As soon as possible');
    }
  }, [ selectedType ])

  function _onChange(e) {
    setSelectedType(e.target.value);
  }

  function _handleMonthChange(e) {
    console.log(e);    
    setMonths(e.target.value);
    setDate(null);
    setValue('Within ' + e.target.value + ' months');
    console.log('set value: ' + selectedVal);    
  }

  function _handleDateChange(dateVal) {
    console.log(dateVal);    
    setDate(dateVal);
    setMonths('');
    setValue('On date: ' + new Date(dateVal).toDateString() );
    console.log('set value: ' + selectedVal);    
  }

  function _renderHelperText() {
    // testing validation
    // const obj = {
    //   isError: isError,
    //   value: selectedVal,
    //   error: error,
    //   touched: touched,
    //   selectedType: selectedType,
    //   months: (selectedType == 'within' && months == ''),
    //   date: (selectedType == 'on date' && date == null)
    // }
    // console.table(obj);
    if (isError) {
      return <FormHelperText>{ error }</FormHelperText>;
    }
  }

  function _helperDom() {
    switch(selectedType) {
      case 'within':
        return (
            <Select
              id="within-months"
              value={ months }
              label="Age"
              onChange={ _handleMonthChange }
            >
                <MenuItem key="month-val" value=''>Select Month Value</MenuItem>
                { monthList.map((item, index) => (
                    <MenuItem key={ index } value={ item }>
                        { item }
                    </MenuItem>
                ))}
            </Select>
        );
      case 'on date':
        return (
            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                <KeyboardDatePicker
                    id="event-date"
                    label="Preferred Event Date"
                    format="MM/dd/yyyy"
                    value={ date }
                    onChange={ _handleDateChange  }
                    minDate= { minDate }
                    KeyboardButtonProps={ {
                      'aria-label': 'change date',
                    } }
                  />
            </MuiPickersUtilsProvider>
        );
    }
  }

  return (
      <FormControl { ...rest } error={ isError }>
          <RadioGroup
            defaultValue=""
            name="radio-buttons-group"
            onChange={ _onChange }
            value={ selectedType }
          >
              <FormControlLabel value="asap" control={ <Radio /> } label={ <div>As soon as Possible</div> } />
              <FormControlLabel value="within" control={ <Radio /> } label={ <div>Within how many months? (choose below)</div> }/>
              <FormControlLabel value="on date" control={ <Radio /> } label={ <div>On date (choose below)</div> }/>
          </RadioGroup>
          {  _helperDom() }
          { _renderHelperText() }
      </FormControl>
  );
}

export default EventTimeField;
