import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, PhoneInput } from '../../FormFields';

export default function ContactInformation(props) {
    const {
        formField: { contact_name, contact_email, contact_phone, email_validation }
    } = props;

	return (
    <React.Fragment>
        <div className='flex flex-wrap w-full md:w-10/12 mx-auto p-2 bg-white bg-opacity-80 lg:bg-transparent' aria-labelledby='organization_contact'>
            <Typography variant="h6" gutterBottom>
                Contact Information
            </Typography>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 6 }>
                    <InputField
                        name={ contact_name.name }
                        label={ contact_name.label }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                    <PhoneInput
                        name={ contact_phone.name }
                        label={ contact_phone.label }
                        fullWidth
                    />
                </Grid> 
                <Grid item xs={ 12 } md={ 6 }>
                    <InputField
                        name={ contact_email.name }
                        label={ contact_email.label }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                    <InputField
                        name={ email_validation.name }
                        label={ email_validation.label }
                        fullWidth
                    />
                </Grid>
            </Grid>
        </div>
    </React.Fragment>
	);
};
