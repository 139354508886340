export default {
    formId: 'requestForm',
    formField: {
      requesting_org_name: {
        name: 'requesting_org_name',
        label: 'Organization Name*',
        requiredErrorMsg: 'Organization Name is required'
      },
      org_address: {
        name: 'org_address',
        label: 'Organization Address*',
        requiredErrorMsg: 'Organization Address is required'
      },
      org_address_additional: {
        name: 'org_address_additional',
        label: 'Suite, Unit, Floor'
      },
      org_zipcode: {
        name: 'org_zipcode',
        label: 'ZIP Code',
        requiredErrorMsg: 'Zipcode is required',
        invalidErrorMsg: 'Zipcode is not valid (e.g. 70000)'
      },
      facility_or_business_type: {
        name: 'facility_or_business_type',
        label: 'Facility or Business Type*',
        requiredErrorMsg: 'Facility or Business type is required'
      },
      org_description: {
        name: 'org_description',
        label: 'Organization Description'
      },      
      contact_name: {
        name: 'contact_name',
        label: 'Contact Name*',
        requiredErrorMsg: 'Contact Name is required'
      },
      contact_phone: {
        name: 'contact_phone',
        label: 'Contact Phone*',
        requiredErrorMsg: 'Phone number is required',
        invalidErrorMsg: 'Invalid phone format - only (000) 000-0000 format accepted'
      },
      contact_email: {
        name: 'contact_email',
        label: 'Contact Email*',
        requiredErrorMsg: 'Email is required',
        invalidErrorMsg: 'Invalid email format'
      },
      email_validation: {
        name: 'email_validation',
        label: 'Verify Email*',
        requiredErrorMsg: 'Validation Email is required',
        invalidErrorMsg: 'Emails must match'
      },
      vaccination_event_type: {
        name: 'vaccination_event_type',
        label: 'Event Type*',
        requiredErrorMsg: 'Event type is required'
      },
      request_time_of_event: {
        name: 'request_time_of_event',
        label: 'Request Time*',
        requiredErrorMsg: 'Request Time is required'
      },
      part_of_larger_event: {
        name: 'part_of_larger_event',
        label: 'Part of larger event?*',
        requiredErrorMsg: 'Part of larger event is required'
      },   
      covid_vaccine_preference: {
        name: 'covid_vaccine_preference',
        label: 'Covid Vaccine Preference*',
        requiredErrorMsg: 'Covid Vaccine Preference is required'
      },
      target_demographics: {
        name: 'target_demographics',
        label: 'Target Age Groups*',
        requiredErrorMsg: 'Target Age Groups required'
      },
      insurance_status: {
        name: 'insurance_status',
        label: 'Insurance Status*',
        requiredErrorMsg: 'Insurance Status is required'
      },
      vaccination_status_assessed: {
        name: 'vaccination_status_assessed',
        label: 'Vaccination status assessed*',
        requiredErrorMsg: 'Vaccination status is required'
      },
      vaccination_wanted_indiv_num: {
        name: 'vaccination_wanted_indiv_num',
        label: '# anticipated*',
        requiredErrorMsg: 'Vaccinations anticipated is required'
      },
      edu_event_topics_to_cover: {
        name: 'edu_event_topics_to_cover',
        label: 'Education topics to cover',
        requiredErrorMsg: 'If education event, topics to cover is required'
      },
      event_location: {
        name: 'event_location',
        label: 'Event Location*',
        requiredErrorMsg: 'Event Location is required'
      },
      hosting_plan_indoor_outdoor: {
        name: 'hosting_plan_indoor_outdoor',
        label: 'Hosting vaccine provider'
      },      
      facility_capacity_bad_weather: {
        name: 'facility_capacity_bad_weather',
        label: 'Facility Capacity Indoors/Outdoors',
        requiredErrorMsg: 'Indoor/outdoor capacity is required'
      },
      on_site_amenities: {
        name: 'on_site_amenities',
        label: 'Onsite Amenities'
      },
      language_needs: {
        name: 'language_needs',
        label: 'Language Needs'
      },
      staff_volunteers_avail: {
        name: 'staff_volunteers_avail',
        label: 'Staff Volunteers Available?*',
        requiredErrorMsg: 'Staff Volunteers Available is required'
      },
      general_comments: {
        name: 'general_comments',
        label: 'Comments'
      }
    }
  };