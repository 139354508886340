import React from 'react';
import Home from './pages/Home';
import Form from './pages/Form';
import { BrowserRouter as Router, Switch, Route,  } from "react-router-dom";
import PageLayout from './components/PageLayout';

const Pages = () => {
    return (
        <Router>
            <Switch>
                <Route path="/" component={ Home } exact />
                <Route path="/form" component={ Form } exact />
            </Switch>
        </Router>
    );
};

function App() {

    return (
        <PageLayout>
            <Pages />
        </PageLayout>
    );
}

export default App;
