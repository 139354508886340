import React, { useState, useEffect } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormControl,
  FormHelperText
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const BooleanField = props => {
  const { label, isDisabled, ...rest } = props;
  const [ field, meta, helper ] = useField(props);
  const [ touched, error ] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const { setValue } = helper;
  const { value } = field;  
  const [ selectedValue, setSelectedValue ] = useState(null);

  useEffect(() => {
    if (value == true || value == false) {
      setSelectedValue(value.toString());
    } else {
      setSelectedValue(null);
    }
  }, [ value ]);

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{ error }</FormHelperText>;
    }
  }

  const handleChange = (event, newVal) => {
      if (newVal === 'true') {
        setValue(true);
      } else if (newVal === 'false') {
        setValue(false);
      }
      setSelectedValue(newVal);
  };

    return (
        <FormControl { ...rest } error={ isError }>
            <ToggleButtonGroup
                color="primary"
                className="mx-auto h-10"
                value={ selectedValue }
                exclusive
                onChange={ handleChange }
                >
                <ToggleButton value="true" className="toggleButton" disabled={ isDisabled }>Yes</ToggleButton>
                <ToggleButton value="false" className="toggleButton" disabled={ isDisabled }>No</ToggleButton>
            </ToggleButtonGroup>
            { _renderHelperText() }
        </FormControl>
    );
}
export default BooleanField;