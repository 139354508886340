import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, CheckboxField, SelectField, MARInput } from '../../FormFields';

const facTypes = [
    {
        label: 'Community-Based Organization',
        value: 'Community-Based Organization'
    },
    {
        label: 'Corporate or other retail business enterprise',
        value: 'Corporate or other retail business enterprise'
    },
    {
        label: 'Non-Governmental Agency',
        value: 'Non-Governmental Agency'
    },    
    {
        label: 'Other Gov Agency (e.g. Federal, Embassy)',
        value: 'Other Gov Agency (e.g. Federal, Embassy)'
    },  
    {
        label: 'DC Government Agency',
        value: 'DC Government Agency'
    },
    {
        label: 'Faith-Based Organization',
        value: 'Faith-Based Organization'
    },
    {
        label: 'Infrastructure',
        value: 'Infrastructure'
    },    
    {
        label: 'Schools/Childcare',
        value: 'Schools/Childcare'
    },    
    {
        label: 'Congregate care',
        value: 'Congregate care'
    },    
    {
        label: 'Other',
        value: 'Other'
    }
];

export default function OrgInformation(props) {
    const {
        formField: {
            requesting_org_name,
            org_address,
            org_address_additional,
            org_zipcode,    
            facility_or_business_type,
            org_description
        }
      } = props;
	return (
    <React.Fragment>
        <section className='flex flex-wrap w-full md:w-10/12 mx-auto p-2 bg-white bg-opacity-80 lg:bg-transparent' aria-labelledby='organization_contact'>
            <Typography variant="h6" gutterBottom>
                Organization Information:
            </Typography>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 12 }>
                    <InputField name={ requesting_org_name.name } label={ requesting_org_name.label } fullWidth />
                </Grid>
                <Grid item xs={ 12 }>
                    <MARInput
                        name={ org_address.name }
                        label={ org_address.label }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 }>
                    <InputField name={ org_address_additional.name } label={ org_address_additional.label } fullWidth />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 }>
                    <InputField name={ org_zipcode.name } label={ org_zipcode.label } fullWidth />
                </Grid>
                <Grid item xs={ 12 }>
                    <SelectField
                        name={ facility_or_business_type.name }
                        label={ facility_or_business_type.label }
                        data={ facTypes }
                        showLabel={ true }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <InputField name={ org_description.name } label={ org_description.label } fullWidth />
                </Grid>
            </Grid>
        </section>
    </React.Fragment>
	);
};