import requestFormModel from './requestFormModel';
const {
  formField: {
    requesting_org_name,
    org_address,
    org_address_additional,
    org_zipcode,
    facility_or_business_type,
    contact_name,
    contact_email,
    contact_phone,
    email_validation,
    org_description,
    vaccination_event_type,
    request_time_of_event,
    part_of_larger_event,
    covid_vaccine_preference,
    target_demographics,
    insurance_status,
    vaccination_status_assessed,
    vaccination_wanted_indiv_num,
    edu_event_topics_to_cover,
    event_location,
    hosting_plan_indoor_outdoor,
    facility_capacity_bad_weather,
    on_site_amenities,
    language_needs,
    staff_volunteers_avail,
    general_comments
  }
} = requestFormModel;

export default {
    [ requesting_org_name.name ]: '',
    [ org_address.name ]: '',
    [ org_address_additional.name ]: '',
    [ org_zipcode.name ]: '',
    [ facility_or_business_type.name ]: '',
    [ contact_name.name ]: '',
    [ contact_email.name ]: '',
    [ contact_phone.name ]: '',
    [ email_validation.name ]: '',
    [ org_description.name ]: '',
    [ vaccination_event_type.name ]: '',
    [ request_time_of_event.name ]: '',
    [ part_of_larger_event.name ]: null,
    [ covid_vaccine_preference.name ]: '',
    [ target_demographics.name ]: '',
    [ insurance_status.name ]: '',
    [ vaccination_status_assessed.name ]: null,
    [ vaccination_wanted_indiv_num.name ]: 0,
    [ edu_event_topics_to_cover.name ]: '',
    [ event_location.name ]: '',
    [ hosting_plan_indoor_outdoor.name ]: '',
    [ facility_capacity_bad_weather.name ]: null,
    [ on_site_amenities.name ]: '',
    [ language_needs.name ]: '',
    [ staff_volunteers_avail.name ]: null,
    [ general_comments.name ]: ''
};
