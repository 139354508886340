import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, BooleanField } from '../../FormFields';

const listitems = [ {
    key: 'list_1',
    value: 'Coordinating with the provider for site visits, site preparation, and scheduling;'
},{
    key: 'list_2',
    value: 'For Moderna/Pfizer, committing to coordinating two events to be held within the recommended intervals;'
},{
    key: 'list_3',
    value: 'Conducting outreach to and recruiting a minimum number of people to be vaccinated at the event (to prevent vaccine wastage);'
},{
    key: 'list_4',
    value: 'Communicating key information, including event/appointment reminders by email or phone, to the target population; '
},{
    key: 'list_5',
    value: 'Gathering all patient demographic and vaccination information needed by the provider prior to the event and ensuring participants provide all needed information/documentation at the event;'
},{
    key: 'list_6',
    value: 'Providing staff/volunteers to assist the provider with operations (e.g. check-in, wiping down surfaces, directing patient flow, enforcing masking and social distancing requirements, audio/visual, etc.)'
} ];

export default function HRInformation(props) {
    const {
        formField: {
            staff_volunteers_avail,
            general_comments
        }
      } = props;
	return (
    <React.Fragment>
        <section className='flex flex-wrap w-full md:w-10/12 mx-auto p-2 bg-white bg-opacity-80 lg:bg-transparent' aria-labelledby='organization_contact'>
            <Typography variant="h6" gutterBottom>
                Human Resources:
            </Typography>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 12 }>
                    <p className="pb-3">The success of any vaccination event depends on your active participation before, during, and after the event. As an organizer, you may be responsible for:</p>
                    <div className="flex flex-col items-start text-left -mb-1 space-y-2.5">
                        {
                            listitems.map(listitem => {
                                return <div key={ listitem.key } className="pl-4">
                                    <span className="bg-indigo-100 text-indigo-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 12H6" />
                                        </svg>
                                    </span>{ listitem.value }
                                </div>
                            })
                        }
                    </div>
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">14.  Do you have staff/volunteers that will be able to carry out any of the responsibilities outlined above prior to, during, and following the event(s)?</p>
                    <BooleanField className="w-full mx-auto sm:m-0"
                        name={ staff_volunteers_avail.name }
                        label={ staff_volunteers_avail.label }
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <p className="italic">15.  General Comments:</p>
                    <InputField 
                        name={ general_comments.name } 
                        label={ general_comments.label }
                        multiline
                        rows="3"
                        fullWidth />
                </Grid>            
            </Grid>
        </section>
    </React.Fragment>
	);
};