import * as Yup from 'yup';
import requestFormModel from './requestFormModel';
const {
  formField: {
    requesting_org_name,
    org_address,
    org_zipcode,
    facility_or_business_type,
    contact_name,
    contact_email,
    contact_phone,
    email_validation,
    vaccination_event_type,
    request_time_of_event,
    part_of_larger_event,
    covid_vaccine_preference,
    target_demographics,
    insurance_status,
    vaccination_status_assessed,
    vaccination_wanted_indiv_num,
    edu_event_topics_to_cover,
    event_location,
    hosting_plan_indoor_outdoor,
    facility_capacity_bad_weather,
    staff_volunteers_avail
  }
} = requestFormModel;

const phoneRegExp = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/;
const zipcodeRegExp = /^\d{5}(?:[-\s]\d{4})?$/;

export default [
  Yup.object().shape({
    [ requesting_org_name.name ]: Yup.string().required(`${ requesting_org_name.requiredErrorMsg }`),
    [ org_address.name ]: Yup.string().required(`${ org_address.requiredErrorMsg }`),
    [ org_zipcode.name ]: Yup.string()
      .matches(zipcodeRegExp, `${ org_zipcode.invalidErrorMsg }`),
    [ facility_or_business_type.name ]: Yup.string().required(`${ facility_or_business_type.requiredErrorMsg }`)
  }),
  Yup.object().shape({
    [ contact_name.name ]: Yup.string().required(`${ contact_name.requiredErrorMsg }`),
    [ contact_phone.name ]: Yup.string()
      .required(`${ contact_phone.requiredErrorMsg }`)
      .matches(phoneRegExp, `${ contact_phone.invalidErrorMsg }`),
    [ contact_email.name ]: Yup.string()
      .required(`${ contact_email.requiredErrorMsg }`)
      .email(`${ contact_email.requiredErrorMsg }`),
    [ email_validation.name ]: Yup.string()
      .required(`${ email_validation.requiredErrorMsg }`)
      .oneOf([ Yup.ref('contact_email'), null ], `${ email_validation.invalidErrorMsg }`)
  }),
  Yup.object().shape({
    [ vaccination_event_type.name ]: Yup.string().required(`${ vaccination_event_type.requiredErrorMsg }`),
    [ request_time_of_event.name ]: Yup.string().required(`${ request_time_of_event.requiredErrorMsg }`),
    [ part_of_larger_event.name ]: Yup.string()
        .required(`${ part_of_larger_event.requiredErrorMsg }`)
        .nullable(`${ part_of_larger_event.requiredErrorMsg }`),
    [ vaccination_status_assessed.name ]: Yup.string()
      .required(`${ vaccination_status_assessed.requiredErrorMsg }`)
      .nullable(`${ vaccination_status_assessed.requiredErrorMsg }`),
    [ vaccination_wanted_indiv_num.name ]: Yup.number()
        .required(`${ vaccination_wanted_indiv_num.requiredErrorMsg }`)
        .min(1, 'Must include more 0 anticipated participants')
        .nullable(`${ vaccination_wanted_indiv_num.requiredErrorMsg }`),
    [ covid_vaccine_preference.name ]: Yup.string()
    .when('vaccination_event_type', {
      is: value => value && value != 'Educational event',
      then: Yup.string()
        .required(`${ covid_vaccine_preference.requiredErrorMsg }`)
        .nullable(`${ covid_vaccine_preference.requiredErrorMsg }`),
      otherwsie: Yup.string()
        .notRequired()
    }),
    [ target_demographics.name ]: Yup.string()
    .when('vaccination_event_type', {
      is: value => value && value != 'Educational event',
      then: Yup.string()
        .required(`${ target_demographics.requiredErrorMsg }`)
        .nullable(`${ target_demographics.requiredErrorMsg }`),
      otherwsie: Yup.string()
        .notRequired()
    }),
    [ insurance_status.name ]: Yup.string()
    .when('vaccination_event_type', {
      is: value => value && value != 'Educational event',
      then: Yup.string()
        .required(`${ insurance_status.requiredErrorMsg }`)
        .nullable(`${ insurance_status.requiredErrorMsg }`),
      otherwise: Yup.string()
        .notRequired()
    }),
    [ edu_event_topics_to_cover.name ]: Yup.string()
    .when('vaccination_event_type', {
      is: value => value && value == 'Educational event',
      then: Yup.string()
        .required(`${ edu_event_topics_to_cover.requiredErrorMsg }`)
        .nullable(`${ edu_event_topics_to_cover.requiredErrorMsg }`),
      otherwise: Yup.string()
        .notRequired()
    }) 
  }),
  Yup.object().shape({
    [ event_location.name ]: Yup.string()
      .required(`${ event_location.requiredErrorMsg }`)
      .nullable(`${ event_location.requiredErrorMsg }`),
    [ hosting_plan_indoor_outdoor.name ]: Yup.string()
    .when('event_location', {
      is: value => value && value.indexOf('At following') > -1,
      then: Yup.string()
        .required(`${ hosting_plan_indoor_outdoor.requiredErrorMsg }`),
      otherwise: Yup.string()
        .notRequired()
    })
  }),
  Yup.object().shape({
    [ staff_volunteers_avail.name ]: Yup.string()
        .required(`${ staff_volunteers_avail.requiredErrorMsg }`)
        .nullable(`${ staff_volunteers_avail.requiredErrorMsg }`)
  }) 
];