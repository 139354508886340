import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, FormControl, FormHelperText } from '@material-ui/core';
import { at } from 'lodash';

// const { API_KEY } = process.env
const { REACT_APP_REQUEST_MAR_URL, REACT_APP_REQUEST_MAR_KEY } = process.env;
const API_KEY = REACT_APP_REQUEST_MAR_KEY;
const API_URL = REACT_APP_REQUEST_MAR_URL;

export default function MARInput(props) {
  const { errorText, ...rest } = props;
  const [ field, meta, helper ] = useField(props);
  const { setValue } = helper;
  const { value: selectedVal } = field;
  const [ results, setResults ] = useState([]);
  const [ touched, error ] = at(meta, 'touched', 'error');  
  const { onChangeValue, onBlurValue, errorBool } = props;
  const isError = touched && error && true;

  useEffect(() => {
    setValue(selectedVal);
  }, [ ]);

  const getInfo = (val) => {
    if (val && val.length > 3){
      fetch(`${ API_URL }/${ val }?apikey=${ API_KEY }`)
        .then(res => res.json())
        .then( (result) => {
            setResults(result.Result);
      })
    }
  }  

  const getSelected = (option, value) => {
    option === value;
  }

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{ error }</FormHelperText>;
    }
  }

  return (
      <FormControl { ...rest } error={ isError }>
          <Autocomplete
              inputValue={ selectedVal }
              renderInput= { (params) =>  
                  <TextField { ...params } 
                      { ...field }
                      { ...rest }
                      label="Organization Address*"
                      fullWidth 
                      placeholder="Search for..."
                      variant="outlined" /> 
              }
              options={ results }
              getOptionSelected={ (option, value) => { console.log(option) } }
              onChange={ (e, value) => {
                setValue(value)
              } }
              onInputChange={ (event, val) => getInfo(val) }
          />
          { _renderHelperText() }
      </FormControl>
  );
}