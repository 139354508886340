
import React from 'react';
import { ReqForm } from '../components/RequestForm/ReqForm';
const Form = () => {
    return (
        <>
            <ReqForm />
        </>
    );
};

export default Form;
