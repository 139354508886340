import Header from './Header';
import Footer from './Footer';
import React from 'react';

const PageLayout = (props) => {
    return (
        <div className="flex flex-col min-h-screen bg-tertiary-light">
            <Header image="logo.png" altText="DC Vaccine Exchange Connect Logo"></Header>
            <div className="flex-1 flex-grow">
                {props.children}
            </div>
            <Footer></Footer>
        </div>
    );
};

export default PageLayout;
